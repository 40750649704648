<template>
  <page-title-component title="Aweber Dashboard"></page-title-component>

  <div class="row mb-2">
    <div class="col-12">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-body">
              <div class="row">
                <div class="col-md-3">
                  <div  class="form-control" id="reportrange">
                    <i class="flaticon-calendar"></i>&nbsp;
                    <span></span>
                  </div>
                </div>
                <div class="col-md-3">
                  <select class="form-control"
                          :disabled="loading"
                          v-model="displayBy">
                    <option value="name">Display By Name</option>
                    <option value="list">Display by List</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-body">
              <p v-if="loading">Loading...</p>
              <p v-if="!loading && !groups.length">No item found.</p>

              <template v-else>
                <div class="card mb-3"
                     v-for="(group, i) in groups"
                     :key="'group_'+i">
                  <div class="card-header">
                    <h6 class="card-title m-0">
                      {{ group.title }}

                      <span class="badge badge-info mr-1"
                            title="Total Subscribers">{{ group.list?.total_subscribers }}</span>
                      <span class="badge badge-success"
                            title="Total Subscribed Subscribers">{{ group.list?.total_subscribed_subscribers }}</span>

                      <span class="float-right text-muted fs-10"
                            v-if="group.list">Last Update: {{ group.list?.updated_at }}</span>
                    </h6>
                  </div>
                  <div class="card-body p-0">
                    <table class="table table-bordered table-sm m-0">
                      <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Sent At</th>
                        <th>Emailed</th>
                        <th>Undelivered</th>
                        <th>Unique Clicks</th>
                        <th>Unique Opens</th>
                        <th>Complaints</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(broadcast, j) in group.broadcasts"
                          :key="'broadcast_'+j">
                        <td>{{ broadcast.subject }}</td>
                        <td>{{ broadcast.sent_at }}</td>
                        <td>{{ broadcast.stats_num_emailed }}</td>
                        <td>{{ broadcast.stats_num_undeliv }}</td>
                        <td>{{ broadcast.stats_unique_clicks }}</td>
                        <td>{{ broadcast.stats_unique_opens }}</td>
                        <td>{{ broadcast.stats_num_complaints }}</td>
                      </tr>
                      <tr class="bg-light-dark" v-if="displayBy === 'list'">
                        <th colspan="2">Summary</th>
                        <th>{{ group.broadcasts.reduce((a, b) => a + b.stats_num_emailed, 0) }}</th>
                        <th>{{ group.broadcasts.reduce((a, b) => a + b.stats_num_undeliv, 0) }}</th>
                        <th>{{ group.broadcasts.reduce((a, b) => a + b.stats_unique_clicks, 0) }}</th>
                        <th>{{ group.broadcasts.reduce((a, b) => a + b.stats_unique_opens, 0) }}</th>
                        <th>{{ group.broadcasts.reduce((a, b) => a + b.stats_num_complaints, 0) }}</th>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      loading: false,
      lists: [],
      displayBy: 'name',
      queryParams: {
        startDate: window.moment().subtract(6, "months").format('YYYY-MM-DD'),
        endDate: window.moment().format('YYYY-MM-DD')
      }
    }
  },
  computed: {
    groups() {
      let groups = [];

      if (!this.lists.length) {
        return groups;
      }

      if (this.displayBy === 'name') {
        groups.push({
          title: 'Broadcasts',
          broadcasts: this.lists.map(l => l.broadcasts).reduce((a, b) => a.concat(b)),
          list: null,
        })
      } else if (this.displayBy === 'list') {
        this.lists.forEach(list => {
          groups.push({
            title: list.name,
            broadcasts: list.broadcasts,
            list: list
          })
        })

        this.lists.forEach(list => {
          groups.push({
            title: list.name,
            broadcasts: list.broadcasts,
            list: list
          })
        })
      }

      return groups;
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    let t = window.moment().subtract(6, "months");
    let a = window.moment();

    let self = this;
    window.$('#reportrange span').html(t.format('MMM D, YYYY') + ' - ' + a.format('MMM D, YYYY'));

    window.$("#reportrange").daterangepicker({
          startDate: t,
          endDate: a,
          locale: {
            format: 'MMM D, YYYY'
          },
          ranges: {
            Today: [window.moment(), window.moment()],
            Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
            "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
            "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
            "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
            "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
            "All Time": [window.moment().subtract(15, "years").startOf("month"), window.moment()],
          },
        },
        function (t, a) {
          self.queryParams.startDate = t.format('YYYY-MM-DD');
          self.queryParams.endDate = a.format('YYYY-MM-DD');

          let rangeText = t.format('MMM D, YYYY') + ' - ' + a.format('MMM D, YYYY');
          window.$('#reportrange span').html(rangeText);

          self.loadData();
        }
    )
  },
  methods: {
    loadData() {
      this.loading = true;

      this.axios.get('/aweber/broadcasts', {
        params: this.queryParams
      }).then(res => {
        this.lists = res.data.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>